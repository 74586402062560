<template>
  <v-container
      id="roles-acl"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-lock"
            inline
            :title="baseMaterialCardTitle"
        >
          <v-row no-gutters v-if="!action">
            <v-col cols="11"></v-col>
            <v-col cols="1" class="text-right align-end">

              <v-btn small right color="info" v-if="preview"
                     @click="$router.push({name: 'editRole', params: {envCode: envSelected.value, id: data.role.id} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
              <v-btn small right color="info" v-else
                     @click="$router.push({name: 'Roles', params: {envCode: envSelected.value} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    class="mt-5"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>

              <base-material-tabs
                  v-else
                  color="success"
                  vertical
                  class="mt-5"
              >
                <template v-for="(serviceVersion, i) in data.service.versions">
                  <v-tab
                      v-if="i !== 'latest'"
                      :key="i"
                      class="mb-1"
                  >
                    {{ i }}
                  </v-tab>
                  <v-tab-item :key="i">
                    <v-card flat class="my-0">
                      <v-card-text>
                        <base-subheading subheading="API Endpoints"/>

                        <v-expansion-panels
                            focusable
                            :multiple=true
                            v-for="(api, method) in serviceVersion.apis.main"
                            :key="method"
                        >
                          <v-expansion-panel
                              v-for="(info, endpoint) in api"
                              :key="endpoint"
                              :disabled="!data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].enabled"
                          >
                            <template v-if="info.access">
                              <v-row no-gutters>
                                <v-col
                                    cols="12"
                                    md="11"
                                    sm="10"
                                >
                                  <v-expansion-panel-header>
                                    <template v-slot:default>
                                      <v-row no-gutters>
                                        <v-col cols="12" class="mt-2">
                                          <v-chip
                                              label
                                              :color="colors[method.toLowerCase()]"
                                          >{{ method.toUpperCase() }}
                                          </v-chip>
                                          <span class="url">
                                            {{ info.label }}
                                            [ {{ endpoint }} ]
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </v-expansion-panel-header>
                                </v-col>

                                <v-col cols="12"
                                       md="1" sm="2"
                                       style="padding-left: 12px"
                                >
                                  <v-tooltip top>
                                    <template v-slot:activator="{on, attrs}">
                                      <div
                                          v-on="on"
                                          v-bind="attrs"
                                      >
                                        <v-switch inset
                                                  v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].enabled"
                                                  @change="clearAccess(i, method, endpoint)">
                                        </v-switch>
                                      </div>
                                    </template>
                                    <span>Enable Endpoint</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>

                              <v-expansion-panel-content>
                                <template
                                    v-if="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].enabled">

                                  <v-switch
                                      :label="`${(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].access) ? 'Allow' : 'Deny'} access to Endpoint`"
                                      v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].access"
                                      @change="clearRBAC(data.role.acl[myEnvUp][service][i], method, endpoint)"
                                      inset>
                                  </v-switch>

                                  <div
                                      v-if="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].access">
                                    <v-subheader class="display-1 mt-3 text-muted"
                                                 v-if="serviceVersion.apis.main[method][endpoint].hasRbac">

                                      <v-row no-gutters>
                                        <v-col cols="10">
                                          Configure the Data Resources Access Control for this Role.
                                        </v-col>
                                        <v-col cols="2" class="justify-end text-right">
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  x-small
                                                  color="warning"
                                                  right
                                                  fab
                                                  @click="copyRbac(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint], info.label)"
                                              >
                                                <v-icon
                                                    small
                                                >mdi-content-copy
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span> Copy RBAC Configuration</span>
                                          </v-tooltip>
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn
                                                  :disabled="rbacClipboard === null"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  x-small
                                                  color="primary"
                                                  right
                                                  fab
                                                  @click="pasteRbac(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint])"
                                                  class="ml-2"
                                              >
                                                <v-icon
                                                    small
                                                >mdi-clipboard
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <span> Paste RBAC Configuration</span>
                                          </v-tooltip>
                                        </v-col>
                                      </v-row>
                                    </v-subheader>
                                    <v-tabs v-if="serviceVersion.apis.main[method][endpoint].hasRbac"
                                            v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].rbacTab">
                                      <v-tabs-slider></v-tabs-slider>
                                      <v-tab
                                          v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.fields">
                                        <v-icon class="mr-1">mdi-variable</v-icon>
                                        Field(s)
                                      </v-tab>
                                      <v-tab
                                          v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.resources">
                                        <v-icon class="mr-1">mdi-database-eye</v-icon>
                                        Resource
                                      </v-tab>
                                      <v-tab
                                          v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.conditions">
                                        <v-icon class="mr-1">mdi-function</v-icon>
                                        Condition(s)
                                      </v-tab>

                                      <v-tabs-items
                                          v-if="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].access"
                                          v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].rbacTab"
                                          class="px-5"
                                      >
                                        <v-tab-item
                                            v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.fields">

                                          <v-switch
                                              v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].fields.operator"
                                              inset
                                              :label="`${(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].fields.operator) ? 'Allow' : 'Deny'} these fields ONLY`"
                                          />

                                          <h4 v-if="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].fields.operator">
                                            Enter the fields that are allowed to be accessed in this API endpoint
                                          </h4>
                                          <h4 v-else>
                                            Enter the fields that should be denied access to in this API endpoint
                                          </h4>
                                          <v-jsoneditor
                                              class="py-2"
                                              v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].fields.list"
                                              :plus="true" :options="{mode: 'code'}"
                                              height="400px"
                                          />

                                        </v-tab-item>
                                        <v-tab-item
                                            v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.resources">
                                          <v-switch
                                              v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.mode"
                                              inset
                                              :label="`Can See, Edit, Delete ${(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.mode) ? 'ANY' : 'Only His'} record(s)`"
                                          />

                                          <template
                                              v-if="!data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.mode">
                                            <v-text-field
                                                :disabled="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.mode"
                                                label="Field *"
                                                :rules="[() => !!data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.field || 'This field is required!']"
                                                v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.field"
                                                counter
                                                required
                                            />
                                            <v-select
                                                :disabled="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.mode"
                                                label="Value *"
                                                v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.value"
                                                :rules="[() => !!data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].resources.value || 'This field is required!']"
                                                required
                                                item-text="text"
                                                item-value="value"
                                                :items="RBACResourcesPredefinedValues"
                                                hint="Select which value should be applied for this RBAC Resources Mapping."
                                                persistent-hint
                                            />
                                          </template>

                                        </v-tab-item>
                                        <v-tab-item
                                            v-if="serviceVersion.apis.main[method][endpoint].rbac && serviceVersion.apis.main[method][endpoint].rbac.conditions">
                                          <v-switch
                                              v-model="data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].conditions.operator"
                                              inset
                                              :label="`${(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].conditions.operator) ? 'ALL' : 'ANY'} condition(s) must be met.`"
                                          />
                                          <template
                                              v-for="(oneCondition, index) in data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].conditions.criteria"
                                          >
                                            <div :key="index">
                                              <v-row>
                                                <v-col cols="11">
                                                  <v-row>
                                                    <v-col cols="4">
                                                      <v-select
                                                          label="Function *"
                                                          v-model="oneCondition.function"
                                                          :rules="[() => !!oneCondition.function || 'This field is required!']"
                                                          required
                                                          item-text="text"
                                                          item-value="value"
                                                          :items="RBACCodnitionFunctions"
                                                          hint="Select which function should be applied for this RBAC Condition."
                                                          persistent-hint
                                                          @change="checkFunction(oneCondition)"
                                                      />
                                                    </v-col>
                                                    <v-col cols="4">
                                                      <v-text-field
                                                          label="Field *"
                                                          :rules="[() => !!oneCondition.arguments.field || 'This field is required!']"
                                                          v-model="oneCondition.arguments.field"
                                                          counter
                                                          required
                                                      />
                                                    </v-col>
                                                    <v-col cols="4">
                                                      <v-select
                                                          label="Value *"
                                                          v-model="oneCondition.arguments.value"
                                                          :rules="[() => !!oneCondition.arguments.value || 'This field is required!']"
                                                          required
                                                          item-text="text"
                                                          item-value="value"
                                                          :items="RBACConditionPredefinedValues"
                                                          hint="Select which value should be applied for this RBAC Condition Mapping."
                                                          persistent-hint
                                                          @change="checkArgument()"
                                                      />
                                                    </v-col>

                                                    <v-col cols="6"
                                                           v-if="['tenant'].includes(oneCondition.arguments.value)">
                                                      <v-text-field
                                                          label="Tenant Code *"
                                                          :rules="[() => !!oneCondition.arguments.tCode || 'This field is required!']"
                                                          v-model="oneCondition.arguments.tCode"
                                                          counter
                                                          required
                                                      />
                                                    </v-col>
                                                    <v-col cols="6"
                                                           v-if="['custom','tenant'].includes(oneCondition.arguments.value)">
                                                      <v-text-field
                                                          label="Custom Value *"
                                                          v-model="oneCondition.arguments.custom"
                                                          counter
                                                          required
                                                      />
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                                <v-col cols="1" class="d-flex align-center justify-center">
                                                  <v-btn color="error" class="white--text" x-small
                                                         left fab
                                                         @click="removeRBACCondition(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].conditions.criteria, index)">
                                                    <v-icon x-small>mdi-delete</v-icon>
                                                  </v-btn>

                                                </v-col>
                                              </v-row>
                                              <v-divider class="my-5"></v-divider>
                                            </div>
                                          </template>

                                          <v-row>
                                            <v-col cols="12">
                                              <v-btn color="primary" class="white--text" small
                                                     @click="AddNewRBACCondition(data.role.acl[myEnvUp][service][i].apis[method.toLowerCase()][endpoint].conditions.criteria)">
                                                <v-icon small class="mr-1">mdi-plus</v-icon>
                                                Add New Condition
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-tab-item>
                                      </v-tabs-items>
                                    </v-tabs>

                                  </div>

                                </template>
                              </v-expansion-panel-content>
                            </template>

                          </v-expansion-panel>
                        </v-expansion-panels>

                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </template>
              </base-material-tabs>
              <v-card-actions
                  v-if="canAccess({method: 'patch', route: `/environments/:env/roles/:id/services/:service`})">
                <v-row no-gutters>
                  <v-col cols="12" class="text-right align-end">
                    <v-btn color="success" @click="updateACL">
                      <v-icon class="mr-1">mdi-content-save</v-icon>
                      save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import globalMixins from "@/mixins/globalMixins";
import VJsoneditor from 'v-jsoneditor';

export default {
  name: 'RolesServiceAddEdit',

  mixins: [globalMixins],

  components: {
    VJsoneditor
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    },
    service: {
      type: String
    },
    action: {
      type: String
    },
    preview: {
      type: Boolean
    }
  },

  data: () => ({
    fieldAccess: {},
    loading: false,
    data: null,
    myEnv: '',
    myEnvUp: '',
    rbacClipboard: null,
    colors: {
      get: 'success',
      post: 'primary',
      put: 'orange',
      delete: 'error',
      patch: 'purple'
    },
    RBACCodnitionFunctions: [
      {text: 'Empty', value: 'EMPTY'},
      {text: 'Not Empty', value: 'NOT_EMPTY'},

      {text: 'Equal', value: 'EQ'},
      {text: 'Not Equal', value: 'NOT_EQ'},

      {text: 'Starts With', value: 'START'},
      {text: 'Does Not start With', value: 'NOT_START'},

      {text: 'Ends With', value: 'END'},
      {text: 'Does Not end With', value: 'NOT_END'},

      {text: 'In', value: 'IN'},
      {text: 'Not In', value: 'NOT_IN'},

      {text: 'Contains', value: 'CONTAIN'},
      {text: 'Does Not contain', value: 'NOT_CONTAIN'},
    ],
    RBACConditionPredefinedValues: [
      {text: "User Id", value: "id"},
      {text: "Username", value: "username"},
      {text: "User E-mail", value: "email"},
      {text: "Organization Id", value: "account._id"},
      {text: "Organization Name", value: "account.name"},
      {text: "Tenant Code", value: "tenant"},
      {text: "Custom", value: 'custom'},
    ],
    RBACResourcesPredefinedValues: [
      {text: "User Id", value: "id"},
      {text: "Organization Id", value: "account._id"},
    ]
  }),

  created() {
    this.myEnv = this.envSelected.value;
    this.myEnvUp = this.envSelected.value.toUpperCase();
    // this.fieldAccess = this.userUpdateAccess();
    this.getRole();
  },

  computed: {
    baseMaterialCardTitle() {
      if (this.data && this.data.service && this.data.role) {
        return `Update the permissions of service '${this.data.service.name}' in ACL Role '${this.data.role.name}'`;
      } else {
        return '';
      }
    }
  },

  methods: {
    getRole() {
      const self = this;
      this.loading = true;
      this.data = null;

      if (this.id) {

        let apiOptions = {
          url: `/consoleapi/environments/${this.myEnv}/roles/${this.id}`,
          method: "get"
        };
        let response = {};
        this.getSendData(apiOptions).then((APIresponse) => {
          response.role = APIresponse.item;

          let apiOptions = {
            url: `/consoleapi/environments/${this.myEnv}/services/name/${this.service}`,
            method: "get"
          };

          this.getSendData(apiOptions)
              .then((APIresponse) => {
                response.service = {name: this.service, versions: {}};

                APIresponse.items.forEach((oneItem) => {
                  if (!response.service.versions['v' + oneItem.version.value]) {
                    response.service.versions['v' + oneItem.version.value] = {};
                  }
                  response.service.versions['v' + oneItem.version.value] = oneItem;
                });

                for (let v in response.service.versions) {
                  for (let m in response.service.versions[v].apis.main) {
                    for (let e in response.service.versions[v].apis.main[m]) {
                      response.service.versions[v].apis.main[m][e].hasRbac = false;
                      if (response.service.versions[v].apis.main[m][e].rbac) {
                        response.service.versions[v].apis.main[m][e].hasRbac =
                            response.service.versions[v].apis.main[m][e].rbac.fields ||
                            response.service.versions[v].apis.main[m][e].rbac.resources ||
                            response.service.versions[v].apis.main[m][e].rbac.conditions;
                      }

                      self.ensureEndpointACL(response, v, m, e);
                    }
                  }
                }

                self.data = response;
                self.loading = false;

              });
        });
      }
    },

    ensureEndpointACL(response, version, method, endpoint) {
      if (!response.role.acl[this.myEnvUp][this.service]) {
        response.role.acl[this.myEnvUp][this.service] = {};
      }
      if (!response.role.acl[this.myEnvUp][this.service][version]) {
        response.role.acl[this.myEnvUp][this.service][version] = {};
      }
      if (!response.role.acl[this.myEnvUp][this.service][version].apis) {
        response.role.acl[this.myEnvUp][this.service][version].apis = {};
      }
      if (!response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()]) {
        response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()] = {};
      }

      if (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint]) {
        response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].enabled = true;
        // validate endpoint
        if (!response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].access) {
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].access = false;
        }

        if (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].fields &&
            response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].fields.operator) {

          let op = (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].fields.operator === 'allow');
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].fields.operator = op;
        } else {
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].fields = {
            operator: true,
            list: {}
          };
        }

        if (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].resources &&
            response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].resources.mode) {
          let op = (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].resources.mode === 'any');
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].resources.mode = op;
        } else {
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].resources = {
            mode: true
          };
        }

        if (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].conditions &&
            response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].conditions.operator) {
          let op = (response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].conditions.operator === '$and');
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].conditions.operator = op;
        } else {
          response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].conditions = {
            operator: false,
            criteria: []
          };
        }
      } else {
        response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint] = {
          enabled: false,
          resources: {
            mode: true
          },
          conditions: {
            operator: false,
            criteria: []
          },
          fields: {
            operator: true,
            list: {}
          }
        };
      }

      if (!response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].rbacTab) {
        response.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].rbacTab = 0;
      }

    },

    clearRBAC(serviceVersion, method, endpoint) {

      if (!serviceVersion.apis[method.toLowerCase()][endpoint]) {
        serviceVersion.apis[method.toLowerCase()][endpoint] = {};
      }

      if (serviceVersion.apis[method.toLowerCase()][endpoint].access) {
        serviceVersion.apis[method.toLowerCase()][endpoint].fields = {
          operator: true,
          list: {}
        };
        if (!serviceVersion.apis[method.toLowerCase()][endpoint].resources) {
          serviceVersion.apis[method.toLowerCase()][endpoint].resources = {
            mode: true,
            field: '',
            value: ''
          };
        }

        if (!serviceVersion.apis[method.toLowerCase()][endpoint].conditions) {
          serviceVersion.apis[method.toLowerCase()][endpoint].conditions = {
            operator: "$and",
            criteria: []
          };
        }

      }
      if (!serviceVersion.apis[method.toLowerCase()][endpoint].access) {
        serviceVersion.apis[method.toLowerCase()][endpoint].fields = {};
        serviceVersion.apis[method.toLowerCase()][endpoint].resources = {};
        serviceVersion.apis[method.toLowerCase()][endpoint].conditions = {};
      }

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    clearAccess(version, method, endpoint) {
      let serviceVersion = this.data.role.acl[this.myEnvUp][this.service][version];
      if (!this.data.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint]) {
        this.data.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint] = {};
      }

      if (!Object.hasOwnProperty(this.data.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint], 'access')) {
        this.data.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].access = false;
      }
      if (this.data.role.acl[this.myEnvUp][this.service][version].apis[method.toLowerCase()][endpoint].enabled) {
        if (!serviceVersion.apis[method.toLowerCase()][endpoint].fields) {
          serviceVersion.apis[method.toLowerCase()][endpoint].fields = {
            operator: true,
            list: {}
          };
        }
      }
      this.$forceUpdate();
    },

    copyRbac(aclObject, endpointLabel) {
      if (this.rbacClipboard) {
        if (confirm(`The Clipboard already contains a copy of an RBAC configuration form endpoint [ ${this.rbacClipboard.label} ].\nDo you want to replace that copy with this one?`)) {
          this.rbacClipboard = {
            fields: {...aclObject.fields},
            resources: {...aclObject.resources},
            conditions: {...aclObject.conditions},
            label: endpointLabel
          };
        }
      } else {
        this.rbacClipboard = {
          fields: {...aclObject.fields},
          resources: {...aclObject.resources},
          conditions: {...aclObject.conditions},
          label: endpointLabel
        };
      }
    },

    pasteRbac(aclObject) {
      if (confirm(`Are you sure you want to paste the copied RBAC configuration of endpoint [ ${this.rbacClipboard.label} ] from the clipboard into the RBAC Configuration of this endpoint?`)) {
        aclObject.fields = {...this.rbacClipboard.fields};
        aclObject.resources = {...this.rbacClipboard.resources};
        aclObject.conditions = {...this.rbacClipboard.conditions};
      }
    },

    removeRBACCondition(stack, index) {
      stack.splice(index, 1);
      this.$forceUpdate();
    },

    AddNewRBACCondition(stack) {
      stack.push({
        function: "EQ",
        arguments: {
          field: "",
          value: "",
          custom: ""
        }
      });
      this.$forceUpdate();
    },

    updateACL() {
      const self = this;
      if (this.action) {
        this.$emit(this.action, this.data.role);
      } else {
        // let payload = { "acl": {} };
        // payload[ 'acl' ][ this.myEnvUp ] = {}
        // payload[ 'acl' ][ this.myEnvUp ][ this.service ] =
        //     this._lodash.cloneDeep( this.data.role.acl[ this.myEnvUp ][ this.service ] );

        let payload = this._lodash.cloneDeep(this.data.role.acl[this.myEnvUp][this.service]);

        for (let v in payload) {
          for (let method in payload[v].apis) {
            for (let api in payload[v].apis[method]) {
              if (payload[v].apis[method][api].enabled === true) {
                delete payload[v].apis[method][api].rbacTab;
                if (payload[v].apis[method][api].access === true) {

                  if (payload[v].apis[method][api].resources) {
                    if (payload[v].apis[method][api].resources.mode === true) {
                      payload[v].apis[method][api].resources = {
                        mode: 'any'
                      }
                    } else {
                      payload[v].apis[method][api].resources.mode = 'own';
                    }
                  }

                  if (payload[v].apis[method][api].fields) {
                    if (payload[v].apis[method][api].fields.operator === true) {
                      payload[v].apis[method][api].fields.operator = 'allow';
                    } else {
                      payload[v].apis[method][api].fields.operator = 'deny';
                    }
                    if (Object.keys(payload[v].apis[method][api].fields.list).length === 0) {
                      // delete payload[v].apis[method][api].fields;
                      payload[v].apis[method][api].fields = {};
                    }
                  }

                  if (payload[v].apis[method][api].conditions) {
                    if (payload[v].apis[method][api].conditions.criteria.length) {
                      for (let i = payload[v].apis[method][api].conditions.criteria.length - 1; i >= 0; i--) {
                        if (payload[v].apis[method][api].conditions.criteria[i].arguments) {

                          if (payload[v].apis[method][api].conditions.criteria[i].function) {
                            if (!payload[v].apis[method][api].conditions.criteria[i].arguments.field ||
                                !payload[v].apis[method][api].conditions.criteria[i].arguments.field) {
                              // delete empty placeholders
                              self.pushMessage({
                                type: 'error',
                                title: `Incomplete data`,
                                text: `Missing condition configuration for ${method} ${api}. You cannot have empty fields.`
                              });
                              self.scrollToTop();
                              return false;
                            }
                          }

                        }
                      }
                    }

                    if (payload[v].apis[method][api].conditions.criteria.length) {
                      if (payload[v].apis[method][api].conditions.operator === true) {
                        payload[v].apis[method][api].conditions.operator = '$and';
                      } else {
                        payload[v].apis[method][api].conditions.operator = '$or';
                      }
                    } else {
                      delete payload[v].apis[method][api].conditions;
                    }
                  }
                } else {
                  delete payload[v].apis[method][api].fields;
                  delete payload[v].apis[method][api].resources;
                  delete payload[v].apis[method][api].conditions;
                }
                delete payload[v].apis[method][api].enabled;
              } else {
                delete payload[v].apis[method][api];
              }

            }
          }
        }

        const apiOptions = {
          url: `/consoleapi/environments/${this.myEnv}/roles/${this.id}/services/${this.service}`,
          method: "patch",
          params: payload
        };

        self.getSendData(apiOptions)
            .then(() => {
              self.pushMessage({
                type: 'success',
                title: `ACL updated`,
                text: `ACL role has been updated!.`
              });
              self.scrollToTop();
            });
      }
    },

    checkFunction(one) {
      if (['EMPTY', 'NOT_EMPTY'].includes(one.function)) {
        one.arguments.value = "empty";
      } else {
        if (one.arguments.value !== "") {
          one.arguments.value = '';
        }
      }
    },

    checkArgument() {
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped lang="scss">
.url {
  margin-left: 30px;
  font-size: large;
}

#roles-acl .v-tabs--pill .v-tab,
#roles-acl .v-tabs--pill .v-tab:before {
  border-radius: 0px;
}
</style>
